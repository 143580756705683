<template>
    <div>
        <b-row>
            <!-- primaryColor -->
            <b-col md="12">
                <b-form-group label="Primary Color" label-for="primaryColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.primaryColor" name="primaryColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.primaryColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- textColor -->
            <b-col md="12">
                <b-form-group label="Text" label-for="textColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.textColor" name="textColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.textColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- buttonColor -->
            <b-col md="12">
                <b-form-group label="Button" label-for="buttonColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.buttonColor" name="buttonColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.buttonColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- buttonTextColor -->
            <b-col md="12">
                <b-form-group label="Button Text" label-for="buttonTextColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.buttonTextColor" name="buttonTextColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.buttonTextColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- buttonTextColor -->
            <b-col md="12" v-show="prop_siteSettingArray.styleType == '6'">
                <b-form-group label="Side Button" label-for="sideButtonColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.sideButtonColor" name="sideButtonColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.sideButtonColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- sideButtonTextColor -->
            <b-col md="12" v-show="prop_siteSettingArray.styleType == '6'">
                <b-form-group label="Side Button Text" label-for="sideButtonTextColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.sideButtonTextColor" name="sideButtonTextColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.sideButtonTextColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!--BUTTON 2 COLOR  -->

            <b-col md="12" v-if="!([5, 7].includes(prop_siteSettingArray.subGoal)) && prop_siteSettingArray.isModalButtonTwo && (isModalButtonTwoShowActive || prop_siteSettingArray.isModalButtonTwo != 0) && prop_siteSettingArray.styleType == 2">
                <b-form-group label="Button 2" label-for="modalButtonTwoColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.modalButtonTwoColor" name="modalButtonTwoColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.modalButtonTwoColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!-- BUTTON 2 TEXT COLOR -->
            <b-col md="12" v-if="!([5, 7].includes(prop_siteSettingArray.subGoal)) && prop_siteSettingArray.isModalButtonTwo && (isModalButtonTwoShowActive || prop_siteSettingArray.isModalButtonTwo != 0) && prop_siteSettingArray.styleType == 2">
                <b-form-group label="Button 2 Text" label-for="modalButtonTwoTextColor">
                    <b-input-group>
                        <b-form-input type="text" v-model="prop_siteSettingArray.modalButtonTwoTextColor" name="modalButtonTwoTextColor" />
                        <b-input-group-append>
                            <b-form-input type="color" v-model="prop_siteSettingArray.modalButtonTwoTextColor" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
        },
        name: 'ColorsSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            },
            isModalButtonTwoShowActive: {
                type: Boolean,
                default: false,
            }
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.primaryColor)) {
                this.prop_siteSettingArray.primaryColor = '#334753';
            }
            if (_.isEmpty(this.prop_siteSettingArray.textColor)) {
                this.prop_siteSettingArray.textColor = '#ffffff';
            }
            if (_.isEmpty(this.prop_siteSettingArray.buttonColor)) {
                this.prop_siteSettingArray.buttonColor = '#ffffff';
            }
            if (_.isEmpty(this.prop_siteSettingArray.buttonTextColor)) {
                this.prop_siteSettingArray.buttonTextColor = '#334753';
            }
            if (_.isEmpty(this.prop_siteSettingArray.sideButtonColor)) {
                this.prop_siteSettingArray.sideButtonColor = '#334753';
            }
            if (_.isEmpty(this.prop_siteSettingArray.sideButtonTextColor)) {
                this.prop_siteSettingArray.sideButtonTextColor = '#ffffff';
            }
            if (_.isEmpty(this.prop_siteSettingArray.modalButtonTwoColor)) {
                this.prop_siteSettingArray.modalButtonTwoColor = '#ffffff';
            }
            if (_.isEmpty(this.prop_siteSettingArray.modalButtonTwoTextColor)) {
                this.prop_siteSettingArray.modalButtonTwoTextColor = '#334753';
            }
        }
    }
</script>

<style type="scss">
    input[type='color'] {
        width: 5rem;
    }
</style>
